/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00bf63;
  color: white;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-logo h1 {
  margin: 0;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.navbar-menu li {
  cursor: pointer;
}

.navbar-menu li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: color 0.3s;
}

.navbar-menu li a:hover {
  color: #d9d9d9;
}

/* Section styling */
.section {
  padding: 100px 20px;
  min-height: 100vh; /* Ensure each section takes up at least the full viewport height */
}

/* Responsive navbar styling */
@media (max-width: 768px) {
  .navbar-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #333;
    flex-direction: column;
    gap: 10px;
    display: none;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    text-align: center;
  }
}

.navbar-logo {
  width: 70px; /* Set the width of the logo container */
  height: auto; /* Maintain aspect ratio */
}

.logo-image {
  max-width: 100%; /* Ensure the logo image doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
}

html,body {
  background-color: #d9d9d9;
 height: 100%;
 width: 100%;
 
}

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Heading styles */
h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

/* Highlight styles */
.highlight {
  color: #00bf63; /* Highlight color */
}

/* Description styles */
.description {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

/* Features section styles */
.features {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.featureshome {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

/* Feature card styles */
.feature {
  padding: 10px;
  background-color: #ffffff; /* Feature card background color */
  border-radius: 8px;
  text-align: center; /* Center the content */
}

.contact-padding{
  padding-right: 30px;
}
.feature img {
  width: 80px;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.feature p {
  font-size: 1rem;
}

/* Call to action button styles */
.cta {
  text-align: center;
}

button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #00bf63; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #00bf63; /* Button hover background color */
}
.section {
  padding: 20px;
}

h2 {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-info {
  text-align: center;
}

.contact-info p {
  margin: 10px 0;
}

.contact-link {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #0056b3;
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-link {
  display: flex;
  align-items: center;
  text-decoration: none; /* Optional: remove underline from link */
  color: inherit; /* Optional: keep link text color same as surrounding text */
  margin-right: 10px;
}

.instagram-logo {
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  margin-right: 8px; /* Space between logo and link text */
  /* Add any other styling you need */
}

.social-link:hover {
  color: #007bff;
}

